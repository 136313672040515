<template>
  <div class="flex flex-col gap-4 w-full">
    <c-card class="w-full flex flex-col p-5 pt-0">
        <CardFooter
          printcard
          reloadcard
          :show-search="false"
          @reload="getEmployeeLeavePlan()"
          @actionModal="onFilter"
          @sortType="onQueryFn({ sort: $event }, null, getEmployeeLeavePlan)"
          @searchResult="onQueryFn({ search: $event }, null, getEmployeeLeavePlan)"
        >
          <template v-slot:rightItems>
            <div class="ml-3">
              <c-button
                label="Create Plan"
                variant="primary btn-bg button-style shadow hover:bg-dynamicBackBtn hover:opacity-90"
                @onClick="$router.push({ name: 'CreateAnnualLeavePlan' })"
              />
            </div>
          </template>
        </CardFooter>
    </c-card>

    <c-table
      :headers="headers"
      :items="leavePlanOptions"
      aria-label="leave plan table"
      :loading="isFetching"
      :has-number="false"
      v-if="leavePlanOptions.length || isFetching"
    >
      <template v-slot:item="{ item }">
        <span v-if="item.leaveYear" class="text-darkPurple text-sm font-normal">
          {{ item.data.leaveYear }}
        </span>
        <span v-if="item.totalMaximumApplicableDays" class="text-darkPurple text-sm font-normal">
          {{ item.data.totalMaximumApplicableDays }}
        </span>
        <span v-if="item.totalPlannedLeaveDays" class="text-darkPurple text-sm font-normal">
          {{ item.data.totalPlannedLeaveDays }}
        </span>
        <span v-if="item.totalUnplannedLeaveDays" class="text-darkPurple text-sm font-normal">
          {{ item.data.totalUnplannedLeaveDays }}
        </span>
        <span v-if="item.updatedAt" class="text-darkPurple text-sm font-normal">
          {{ $DATEFORMAT(new Date(item.data.updatedAt), 'MMMM dd, yyyy') }} <br />
          <span class="text-xs font-semibold text-blueCrayola">
            {{ $DATEFORMAT(new Date(item.data.updatedAt), 'hh:mm a') }}
          </span>
        </span>
        <c-menu v-if="item.leavePlanId" left @click.stop="">
          <template v-slot:title>
            <c-icon icon-name="more_icon" size="xs" />
          </template>
          <div class="flex flex-col w-28 h-18 p-2 justify-start items-start">
            <div class="w-full" @click="$router.push({ name: 'EditAnnualLeavePlan', params: {mode: 'view', id: item.data.leavePlanId} })">
              <div class="hover:bg-ghostWhite w-full rounded-md">
                <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                  <c-icon icon-name="icon-eye" class-name="text-darkPurple" size="xs" />
                  <span class="text-darkPurple text-sm leading-5 font-normal">View</span>
                </div>
              </div>
            </div>
            <div class="w-full" @click="$router.push({ name: 'EditAnnualLeavePlan', params: {mode: 'edit', id: item.data.leavePlanId} })">
              <div class="hover:bg-ghostWhite w-full rounded-md">
                <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                  <c-icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                  <span class="text-darkPurple text-sm leading-5 font-normal">Edit</span>
                </div>
              </div>
            </div>
          </div>
        </c-menu>
      </template>
    </c-table>

    <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
      <c-icon icon-name="paygrade_Illustration" size="" class-name="w-56 h-56" />
      <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
        Annual leave plans can be found here. This is empty now,
        but you can start adding annual leave to populate this area.
      </p>
    </div>

    <FilterTable
      v-if="isOpenFilter"
      :filter-data="filterOptions"
      @close="isOpenFilter = false"
      @submit="isOpenFilter = false"
    />
  </div>
</template>

<script>
import CButton from "@scelloo/cloudenly-ui/src/components/button"
import CTable from "@scelloo/cloudenly-ui/src/components/table"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu"
import CIcon from "@/components/Icon"
import CardFooter from "@/components/CardFooter"
import FilterTable from "@/components/FilterTable"

export default {
  components: {
    CMenu,
    CButton,
    CCard,
    CIcon,
    CardFooter,
    CTable,
    FilterTable
  },
  data() {
    return {
      isOpenFilter: false,
      isFetching: false,
      filterOptions: [],
      setQueryTimeout: null,
      queryParams: {},
      leavePlanOptions: [],
      headers: [
        { title: "Leave Year", value: "leaveYear" },
        { title: "Total Leave Entitlement (Days)", value: "totalMaximumApplicableDays" },
        { title: "Planned Leave (Days)", value: "totalPlannedLeaveDays" },
        { title: "Unplanned Leave (Days)", value: "totalUnplannedLeaveDays" },
        { title: "Last Modified", value: "updatedAt" },
        { title: "", value: "leavePlanId", image: true },
      ],
    };
  },
  methods: {
    onQueryFn(queryParams, payload, callback){
      clearTimeout(this.setQueryTimeout);
      this.setQueryTimeout = setTimeout(() => {
        if(queryParams === undefined){
          this.queryParams = {}
          return callback({ ...this.queryParams, ...payload })
        }
        this.queryParams = {...this.queryParams, ...queryParams}
        return callback({ ...this.queryParams, ...payload })
      }, 1000);
    },
    onFilter(){
      this.filterOptions = [
        { header: 'Location(s)', optionsBox: this.locationOptions },
        { header: 'Function(s)', optionsBox: this.functionOptions },
        { header: 'Level(s)', optionsBox: this.levelOptions },
        { header: 'Designation(s)', optionsBox: this.designationOptions },
      ]
      this.isOpenFilter = true
    },
    async getEmployeeLeavePlan(params) {
      this.isFetching = true
      const payload = { userId: this.$AuthUser.id, params }
      await this.$_getEmployeeLeavePlan(payload).then(({ data }) => {
        this.leavePlanOptions = data.data.map((item) => ({
          ...item, isModified: false
        }))
        this.isFetching = false
      }).catch(() => {
        this.$toasted.error("Error fetching data, please check your network.", { duration: 3000 })
        this.isFetching = false
      })
    },
    async bootstrapModule(){
      await this.getEmployeeLeavePlan()
    }
  },
  async created() {
    this.bootstrapModule()
  }
};
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
</style>
